@import "~react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
@import "~chartist/dist/chartist.min.css";
@import "~nouislider/distribute/nouislider.min.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~react-bootstrap-typeahead/css/Typeahead.css';
@import '~react-bootstrap-typeahead/css/Typeahead-bs4.css';
@import '~react-datepicker/dist/react-datepicker.css';
@import '~react-circular-progressbar/dist/styles.css';

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "../assets/styles/variables";
@import "~compass-mixins/lib/compass";
@import "~compass-mixins/lib/animate";
@import "~bootstrap/scss/bootstrap";
@import "../assets/styles/fonts";
@import "../assets/styles/functions";

/* === Icon fonts === */
@import "~@mdi/font/scss/materialdesignicons";
@import "~font-awesome/scss/font-awesome";
@import "~flag-icon-css/sass/flag-icon";
@import "~simple-line-icons/scss/simple-line-icons";
@import "~simple-line-icons/scss/simple-line-icons";

/* === Template mixins === */
@import "../assets/styles/mixins/animation";
@import "../assets/styles/mixins/badges";
@import "../assets/styles/mixins/buttons";
@import "../assets/styles/mixins/misc";
@import "../assets/styles/mixins/color-functions";
@import "../assets/styles/mixins/cards";
@import "../assets/styles/mixins/blockqoute";
@import "../assets/styles/mixins/popovers";
@import "../assets/styles/mixins/tooltips";
@import "../assets/styles/mixins/no-ui-slider";

/* === Core Styles === */
@import "../assets/styles/background";
@import "../assets/styles/typography";
@import "../assets/styles/reset";
@import "../assets/styles/responsive";
@import "../assets/styles/misc";
@import "../assets/styles/utilities";
@import "../assets/styles/demo";
@import "../assets/styles/spinner";
@import "../assets/styles/dashboard";

/* === Components === */

@import "../assets/styles/components/widgets";
@import "../assets/styles/components/forms";
@import "../assets/styles/components/checkbox-radio";
@import "../assets/styles/components/icons";
@import "../assets/styles/components/tables";
@import "../assets/styles/components/accordions";
@import "../assets/styles/components/buttons";
@import "../assets/styles/components/breadcrumbs";
@import "../assets/styles/components/badges";
@import "../assets/styles/components/cards";
@import "../assets/styles/components/preview";
@import "../assets/styles/components/tooltips";
@import "../assets/styles/components/user-profile";
@import "../assets/styles/components/popovers";
@import "../assets/styles/components/lists";
@import "../assets/styles/components/bootstrap-progress";
@import "../assets/styles/components/bootstrap-alerts";
@import "../assets/styles/components/tabs";
@import "../assets/styles/components/dropdowns";
@import "../assets/styles/components/pagination";
@import "../assets/styles/components/loaders/loaders";
@import "../assets/styles/components/timeline";
@import "../assets/styles/components/portfolio";
@import "../assets/styles/components/pricing-table";
@import "../assets/styles/components/email/mail-list-container";
@import "../assets/styles/components/email/mail-sidebar";
@import "../assets/styles/components/email/message-content";
@import "../assets/styles/components/todo-list";
@import "../assets/styles/components/spinner";
@import "../assets/styles/components/tickets";
@import "../assets/styles/components/project-list";
@import "../assets/styles/components/product-tile";
@import "../assets/styles/components/user-listing";
@import "../assets/styles/components/landing";
@import "../assets/styles/components/chats";
@import "../assets/styles/components/kanban";
@import "../assets/styles/components/react-table";

@import "../assets/styles/components/landing-screens/auth";

/* === Plugin Overrides === */
@import "../assets/styles/components/plugin-overrides/contex-menu";
@import "../assets/styles/components/plugin-overrides/no-ui-slider";
@import "../assets/styles/components/plugin-overrides/slick-carousel";
@import "../assets/styles/components/plugin-overrides/rating";
@import "../assets/styles/components/plugin-overrides/date-picker";
@import "../assets/styles/components/plugin-overrides/wizard";
@import "../assets/styles/components/plugin-overrides/react-bootstrap-table";
@import "../assets/styles/components/plugin-overrides/sweet-alert";
@import "../assets/styles/components/plugin-overrides/full-calendar";
@import "../assets/styles/components/plugin-overrides/react-photo-gallery";
@import "../assets/styles/components/plugin-overrides/react-tag-autocomplete";
@import "../assets/styles/components/plugin-overrides/jquery-jvectormap";
@import "../assets/styles/components/plugin-overrides/react-table";

/* === Layout === */
@import "../assets/styles/navbar";
@import "../assets/styles/sidebar";
@import "../assets/styles/footer";
@import "../assets/styles/layout";
@import "../assets/styles/settings-panel";

@import '~primereact/resources/themes/saga-blue/theme.css';
@import '~primereact/resources/primereact.min.css';
@import '~primeicons/primeicons.css';
@import '~sweetalert/dist/sweetalert.css';
@import '~react-toastify/dist/ReactToastify.css';
@import "../assets/font-awesome/css/all.css";
@import "../assets/styles/preloader";

.breadcrumb-item {
  a {
    &:hover {
      text-decoration: unset !important;
    }
  }
}

textarea {
  &.form-control {
    height: 136px;
  }
}

.p-multiselect-label {
  padding-top: 0 !important;
}

.form-control, .p-calendar .p-inputtext, .p-button.p-button-icon-only, .custom-file .custom-file-label {
  height: 34px !important;
}

textarea.form-control {
  height: 112px !important;
}

.p-multiselect {
  padding: 4px !important;
}

.custom-delete-alert {
  position: fixed;
  top: 20vw;
  left: 25vw;
  width: 50vw;
  height: auto;
  padding: 12px;
  border-radius: 7px;
  background: #f3f3f3;
  border: 1px solid #647286;
  z-index: 1;
}

.pull-right {
  float: right
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: .25rem;
}

.page-item {
  &.disabled {
    .page-link {
      color: #6c757d;
      pointer-events: none;
      cursor: auto;
      background-color: #fff;
      border-color: #dee2e6;
    }
  }

  &:first-child {
    .page-link {
      margin-left: 0;
      border-top-left-radius: .25rem;
      border-bottom-left-radius: .25rem;
    }
  }
}

.page-link {
  font-size: 12px;
  background-color: #eaeaea !important;
  line-height: 1.15 !important;
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  color: #007bff;
  border: 1px solid #dee2e6;

  &.page-counter {
    background-color: #f3f3f3 !important;
    min-width: 80px;
  }
}

.comment-wrapper {
  .panel-body {
    max-height: 650px;
    overflow: auto;
  }

  .media-list {
    .media {
      border-bottom: 1px dashed #efefef;
      margin-bottom: 25px;

      img {
        width: 64px;
        height: 64px;
        border-radius: 25%;
        border: 2px solid #e5e7e8;
      }
    }
  }
}

.p-dialog {
  min-width: 35% !important;
}

.nav-link {
  cursor: pointer;
}

.horizontal-timeline {
  padding: 0 12px;
  border-radius: 7px;
  margin-top: 10px;
  border: 1px solid #6c729347;
  display: none;

  &.active {
    display: block;
  }

  .time-frame {
    border-bottom: 1px solid $border-color;
    padding-top: 20px;

    &:last-child {
      border-bottom: none;
    }

    .section-time-frame {
      margin-bottom: 30px;
    }

    .event {
      border-left: 1px solid $border-color;
      padding-left: 2rem;
      padding-bottom: 30px;
      position: relative;

      &:last-child {
        border-left: none;
      }

      .event-text {
        line-height: 1;
        font-weight: 400;
        margin-bottom: 0;
        @include ellipsor;
      }

      .tumbnail-views {
        display: inline-block;

        .thumbnail {
          float: left;
          margin-right: 16px;
          margin-top: 1rem;

          img {
            max-width: 100%;
            border-radius: 4px;
          }
        }
      }

      .event-alert {
        margin-top: 1rem;
        padding: 11px 25px;
        background: red;
        width: 80%;
        font-size: 13px;
        font-weight: 300;
        border-radius: 4px;
      }

      .event-info {
        margin-top: 0.8rem;
        font-size: 12px;
        font-weight: 400;
        color: $text-muted;
      }

      .buttons {
        position: absolute;
        right: 0;
        top: 10px;
        font-size: 14px;
      }

      &:before {
        background: theme-color(success);
        content: "";
        position: absolute;
        top: 0;
        left: -5px;
        height: 10px;
        width: 10px;
        border-radius: 100%;
      }
    }
  }
}

.p-dropdown-label {
  height: 34px !important;
  padding: 5px 10px;
}

.filter-container {
  text-align: center;
  display: inline-flex;
}

.filter-button {
  margin: auto auto 0 auto !important;
}

.nav-link.active {
  color: #fff;
}

input, span {
  font-size: 13px !important;
}

#root {
  overflow: hidden;
  max-height: 100vh;
}

.table-responsive {
  max-height: calc(100vh - 270px);
}

.profile-feed {
  display: none;

  &.active {
    display: block;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }
}

.forms-sample {
  max-height: calc(100vh - 170px);
  overflow-y: auto;
  overflow-x: hidden !important;

  .container {
    width: 100% !important;
    max-width: 100% !important;
    margin: 0;
    padding: 0;
  }
}

.notification-list {
  max-height: 450px;
  overflow-y: auto !important;
}


.badge-1 {
  color: #fff;
  background-color: #19d895;
}

.badge-2 {
  color: #fff;
  background-color: #23c3dd;
}

.badge-3 {
  color: #fff;
  background-color: #4199f1;
}

.badge-4 {
  color: #212529;
  background-color: #fac834;
}

.badge-5 {
  color: #fff;
  background-color: #6c757d;
}

.comment-link {
  text-decoration: unset !important;
}

.emoji {
  position: absolute;
  bottom: 70px;
  z-index: 1000;

  .content-wrapper {
    background: unset !important;
    padding: 0 !important;
  }

  .emoji-picker-react .emoji-group:before {
    z-index: 1001;
  }
}

.img-fluid {
  height: 120px;
}

.selected-file {
  position: absolute;
  display: grid;
  left: 0;
  right: 0;
  top: -41px;
  height: 40px;
  color: #8086a2;
  padding: 3px 0 3px 10px;
  background: #ffffff;
  border-top: 1px solid #dee2e652;

  .name {

  }

  .size {
    font-size: 11px !important;
  }

  .text-danger {
    position: absolute;
    font-size: 12px;
    right: 6px;
    top: 30%;
  }
}

.chat-documentation {
  max-height: 430px;
  overflow-y: auto;
}

.radio-label {
  margin: auto 15px;
}

.fr-wrapper {
  background: #FFF;
  border-radius: 0 0 10px 10px;
  border: 1px solid #CCCCCC !important;
}

.fr-second-toolbar, .tox-statusbar__branding {
  display: none !important;
}

.rtl {
  .fr-element {
    p {
      direction: rtl;
      float: right;
    }
  }

  .fr-placeholder {
    float: right !important;
    text-align: right !important;
  }
}

.errorMessage {
  font-size: 11px;
}

.errorInput {
  border: 1px solid #ff0000 !important;
}

.btn-dh-danger {
  border: 1px solid #c70007 !important;
  background-color: #c70007 !important;
  color: #FFFFFF;
  border-radius: 5px;
}

.btn-dh-default {
  border: 1px solid #b6b6b6 !important;
  background-color: #b6b6b6 !important;
  border-radius: 5px;
}

.btn-dh-c4 {
  border: 1px solid #c4c4c4 !important;
  background-color: #c4c4c4 !important;
  color: #FFFFFF;
  border-radius: 5px;
}

.btn-dh-grey {
  border: 1px solid #8a8a8a !important;
  background-color: #8a8a8a !important;
  border-radius: 5px;
}

.btn-dh-dark {
  border: 1px solid #090E21 !important;
  background-color: #090E21 !important;
  color: #FFFFFF;
  border-radius: 5px;
}

.tickets-tab-switch {
  .tab-pane {
    max-height: calc(100vh - 365px);
    overflow-y: auto;
  }
}

.nav-profile {
  .dropdown-menu {
    max-height: 350px;
    overflow: auto !important;
  }
}

thead tr th {
  position: sticky;
  top: 0;
}

table {
  border-collapse: collapse;
}

th {
  padding: 16px;
  background: #f3f4fa;
  text-align: left;
  box-shadow: 0 0 0 2px #e8e8e8;
}

table {
  width: 100%;
  font-family: sans-serif;
}

table td {
  padding: 16px;
}

tbody tr {
  border-bottom: 2px solid #e8e8e8;
}

thead {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}

tbody tr:hover {
  background: #e6f7ff;
}

.close-header {
  display: flex;
  padding: 5px 15px;

  label {
    margin-right: 10px;
  }
}
